
    
    .title{
        font-size:6rem;
        font-family: "Horev";
        text-align: center;
        margin:2% auto;
        direction:rtl;
        color: black;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
    }
    
    
    .description{
        font-size:3.5rem;
        font-family: "Horev";
        text-align: center;
        margin:2% auto;
        direction:rtl;
        color: black;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
    }
    .center{
        grid-template-columns: repeat(1, auto); /* Two items per row */
      display: flex;
        width:50%;
        margin: 2% auto;
    }
    .form{
        width:80vw;
        height:auto;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
      margin:2% auto;
     
    }
    .row {
        display: grid;
        grid-template-columns: repeat(2, auto); /* Two items per row */
        justify-content: space-evenly; /* Align items to the end (right side) */
        gap: 30px; /* Gap between items */
        direction: rtl; /* Right-to-left direction */
        margin:2% auto;
   
    }
    .input{
   
        background: radial-gradient(circle, rgba(249, 209, 247, 0.496) 0%, rgba(255, 255, 255, 0.504) 100%);
        box-shadow: 0 0 8px 2px rgb(255, 195, 187);
        height: 3.5rem;
        width: 18rem;
        border-radius: 10px;
        margin: 2% auto;
        color:black;
        font-family: "RubikR";
        font-size: 16px;
        border: none;
        padding: 0 10px;
        direction: rtl;
        /* Additional box shadow */
       
    }
    .input::placeholder{
        color: rgb(0, 0, 0);
        font-family: "RubikR";
       
    }
    .input:focus{
        border: none ;
        outline: none;
    }
    .button{

        height:3rem;
        width:100%;
        background: radial-gradient(circle, rgba(249, 209, 247, 0.496) 0%, rgba(255, 255, 255, 0.504) 100%);
        box-shadow: 0 0 8px 2px rgb(255, 195, 187);
        border-radius: 10px;
        margin:2% auto;
        cursor: pointer;
        font-family: "RubikR";
        font-size: 16px;
        border: none;
        text-decoration: none;
        direction: rtl;
        transition: all ease-out 1s;
    }
    .button:hover{
    transform: scale(1.1);
  
    }
    
    @media only screen and (max-width: 350px){
        .center{
            display: flex;
            justify-content: center;
            margin:0 auto;
            }
            
            .title{
                font-size:2rem;
                font-family: "Horev";
                width:70%;
                text-align: center;
                margin:3% auto;
                direction:rtl
            }
               
    .description{
        font-size:2.5rem;
        font-family: "Horev";
        text-align: center;
        margin:2% auto;
        direction:rtl;
        color: black;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
    }
            
            .form{
                width:100vw;
                height:auto;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                margin: 2% auto;
            }
            .row {
                display: flex;
        flex-direction: column;
                flex-wrap: wrap;
           
            }
            
            .input{
   
                background: radial-gradient(circle, rgba(249, 209, 247, 0.496) 0%, rgba(255, 255, 255, 0.504) 100%);
                box-shadow: 0 0 8px 2px rgb(255, 195, 187);
                height: 3.5rem;
                width: 80vw;
                border-radius: 10px;
                margin: 2% auto;
                font-family: "RubikR";
                font-size: 16px;
                border: none;
                padding: 0 10px;
                direction: rtl;
                /* Additional box shadow */
               
            }
            .input::placeholder{
                color: rgb(0, 0, 0);
                font-family: "RubikR";
           
               
            }
            .input:focus{
                border: none ;
                outline: none;
              opacity: 0.8;
            }
            .button{

                height:3rem;
                width:100%;
                background: radial-gradient(circle, rgba(249, 209, 247, 0.496) 0%, rgba(255, 255, 255, 0.504) 100%);
                box-shadow: 0 0 8px 2px rgb(255, 195, 187);
                border-radius: 10px;
                margin:2% auto;
                cursor: pointer;
                font-family: "RubikR";
                font-size: 16px;
                border: none;
                color:black;
                text-decoration: none;
                direction: rtl;
                transition: all ease-out 1s;
            }
            .button:hover{
            transform: scale(1.1);
         opacity: 0.8;
            }
            
    }
    @media only screen  and (min-width: 350px) and (max-width: 450px){
        .center{
            display: flex;
            justify-content: center;
            margin:0 auto;
            }
            
            .title{
                font-size:2.5rem;
                font-family: "Horev";
                width:90%;
                text-align: center;
                margin:4% auto;
                direction:rtl
            }
            
            .form{
                width:100vw;
                height:auto;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                margin: 2% auto;
            }
            .row {
                display: flex;
        flex-direction: column;
                flex-wrap: wrap;
           
            }
            
            .input{
   
                background: radial-gradient(circle, rgba(249, 209, 247, 0.496) 0%, rgba(255, 255, 255, 0.504) 100%);
                box-shadow: 0 0 8px 2px rgb(255, 195, 187);
                height: 3.5rem;
                width: 85vw;
                border-radius: 10px;
                margin: 2% auto;
                font-family: "RubikR";
                font-size: 16px;
                border: none;
                padding: 0 10px;
                direction: rtl;
                /* Additional box shadow */
               
            }
            .input::placeholder{
                color: rgb(0, 0, 0);
                font-family: "RubikR";
           
               
            }
            .input:focus{
                border: none ;
                outline: none;
                opacity: 0.8;
            }
            .button{

                height:3rem;
                width:100%;
                background: radial-gradient(circle, rgba(249, 209, 247, 0.496) 0%, rgba(255, 255, 255, 0.504) 100%);
                box-shadow: 0 0 8px 2px rgb(255, 195, 187);
                border-radius: 10px;
                margin:2% auto;
                cursor: pointer;
                font-family: "RubikR";
                font-size: 16px;
                border: none;
                direction: rtl;
                color:black;
                text-decoration: none;
                transition: all ease-out 1s;
            }
            .button:hover{
            transform: scale(1.1);
            background: radial-gradient(circle, rgba(176, 186, 255, 0.514) 0%, rgba(252, 215, 255, 0.251) 100%);
            }

    }
    @media only screen  and (min-width: 450px) and (max-width: 550px){
        .center{
            display: flex;
            justify-content: center;
            margin:0 auto;
            }
            
            .title{
                font-size:3rem;
                font-family: "Horev";
                width:90%;
                text-align: center;
                margin:4% auto;
                direction:rtl
            }
            .row {
                display: flex;
        flex-direction: column;
                flex-wrap: wrap;
           
            }
            
            .form{
                width:100vw;
                height:auto;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                margin: 2% auto;;
            }

            
            .input{
   
                background: radial-gradient(circle, rgba(249, 209, 247, 0.496) 0%, rgba(255, 255, 255, 0.504) 100%);
                box-shadow: 0 0 8px 2px rgb(255, 195, 187);
                height: 3.5rem;
                width: 80vw;
                border-radius: 10px;
                margin: 2% auto;
                font-family: "RubikR";
                font-size: 16px;
                border: none;
                padding: 0 10px;
                direction: rtl;
                /* Additional box shadow */
               
            }
            .input::placeholder{
                color: rgb(0, 0, 0);
                font-family: "RubikR";
           
               
            }
            .input:focus{
                border: none ;
                outline: none;
                opacity: 0.8;
            }
            .button{

                height:3rem;
                width:100%;
                background: radial-gradient(circle, rgba(249, 209, 247, 0.496) 0%, rgba(255, 255, 255, 0.504) 100%);
                box-shadow: 0 0 8px 2px rgb(255, 195, 187);
                border-radius: 10px;
                margin:2% auto;
                cursor: pointer;
                color:black;
                text-decoration: none;
                font-family: "RubikR";
                font-size: 16px;
                border: none;
                direction: rtl;
                transition: all ease-out 1s;
            }
            .button:hover{
            transform: scale(1.1);
            background: radial-gradient(circle, rgba(176, 186, 255, 0.514) 0%, rgba(252, 215, 255, 0.251) 100%);
            }
            
    }
    @media only screen  and (min-width: 550px) and (max-width: 650px){
        .center{
            display: flex;
            justify-content: center;
            margin:0 auto;
            }
            
            .title{
                font-size:3.5rem;
                font-family: "Horev";
                width:90%;
                text-align: center;
                margin:2% auto;
                direction:rtl
            }
            .row {
                display: flex;
        flex-direction: column;
                flex-wrap: wrap;
           
            }

            .form{
                width:100vw;
                height:auto;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                margin: 2% auto;;
            }
            
            .input{
   
                background: radial-gradient(circle, rgba(249, 209, 247, 0.496) 0%, rgba(255, 255, 255, 0.504) 100%);
                box-shadow: 0 0 8px 2px rgb(255, 195, 187);
                height: 3.5rem;
                width: 75vw;
                border-radius: 10px;
                margin: 2% auto;
                font-family: "RubikR";
                font-size: 16px;
                border: none;
                padding: 0 10px;
                direction: rtl;
                /* Additional box shadow */
               
            }
            .input::placeholder{
                color: rgb(0, 0, 0);
                font-family: "RubikR";
           
               
            }
            .input:focus{
                border: none ;
                outline: none;
                opacity: 0.8;
            }
            .button{

                height:3rem;
                width:100%;
                background: radial-gradient(circle, rgba(249, 209, 247, 0.496) 0%, rgba(255, 255, 255, 0.504) 100%);
                box-shadow: 0 0 8px 2px rgb(255, 195, 187);
                border-radius: 10px;
                margin:2% auto;
                cursor: pointer;
                font-family: "RubikR";
                font-size: 16px;
                border: none;
                direction: rtl;
                color:black;
                text-decoration: none;
                transition: all ease-out 1s;
            }
            .button:hover{
            transform: scale(1.1);
            background: radial-gradient(circle, rgba(176, 186, 255, 0.514) 0%, rgba(252, 215, 255, 0.251) 100%);
            }
            
    }
    @media only screen  and (min-width: 650px) and (max-width: 750px){
        .center{
            display: flex;
            justify-content: center;
            margin:0 auto;
            }
    
            .title{
                font-size:3.5rem;
                font-family: "Horev";
                width:90%;
                text-align: center;
                margin:2% auto;
                direction:rtl
            }
            .row {
                display: flex;
        flex-direction: column;
                flex-wrap: wrap;
           
            }
            
            .form{
                width:100vw;
                height:auto;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                margin: 2% auto;;
            }
            
            .input{
   
                background: radial-gradient(circle, rgba(249, 209, 247, 0.496) 0%, rgba(255, 255, 255, 0.504) 100%);
                box-shadow: 0 0 8px 2px rgb(255, 195, 187);
                height: 3.5rem;
                width: 70vw;
                border-radius: 10px;
                margin: 2% auto;
                font-family: "RubikR";
                font-size: 16px;
                border: none;
                padding: 0 10px;
                direction: rtl;
                /* Additional box shadow */
               
            }
            .input::placeholder{
                color: rgb(0, 0, 0);
                font-family: "RubikR";
           
               
            }
            .input:focus{
                border: none ;
                outline: none;
                opacity: 0.8;
            }
            .button{

                height:3rem;
                width:100%;
                background: radial-gradient(circle, rgba(249, 209, 247, 0.496) 0%, rgba(255, 255, 255, 0.504) 100%);
                box-shadow: 0 0 8px 2px rgb(255, 195, 187);
                border-radius: 10px;
                margin:2% auto;
                cursor: pointer;
                font-family: "RubikR";
                font-size: 16px;
                border: none;
                color:black;
                text-decoration: none;
                direction: rtl;
                transition: all ease-out 1s;
            }
            .button:hover{
            transform: scale(1.1);
            background: radial-gradient(circle, rgba(176, 186, 255, 0.514) 0%, rgba(252, 215, 255, 0.251) 100%);
            }  
    }
    @media only screen  and (min-width: 750px) and (max-width: 850px){
        .center{
            display: flex;
            justify-content: center;
            margin:0 auto;
            }
            
            .title{
                font-size:3.2rem;
                font-family: "Horev";
                width:90%;
                text-align: center;
                margin:2% auto;
                direction:rtl
            }
            .row {
                display: flex;
        flex-direction: column;
                flex-wrap: wrap;
           
            }
     
            
            .form{
                width:100vw;
                height:auto;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                margin: 2% auto;;
            }
            
            .input{
   
                background: radial-gradient(circle, rgba(249, 209, 247, 0.496) 0%, rgba(255, 255, 255, 0.504) 100%);
                box-shadow: 0 0 8px 2px rgb(255, 195, 187);
                height: 3.5rem;
                width: 60vw;
                border-radius: 10px;
                margin: 2% auto;
                font-family: "RubikR";
                font-size: 16px;
                border: none;
                padding: 0 10px;
                direction: rtl;
                /* Additional box shadow */
               
            }
            .input::placeholder{
                color: rgb(0, 0, 0);
                font-family: "RubikR";
           
               
            }
            .input:focus{
                border: none ;
                outline: none;
                opacity: 0.8;
            }
            .button{

                height:3rem;
                width:100%;
                background: radial-gradient(circle, rgba(249, 209, 247, 0.496) 0%, rgba(255, 255, 255, 0.504) 100%);
                box-shadow: 0 0 8px 2px rgb(255, 195, 187);
                border-radius: 10px;
                margin:2% auto;
                cursor: pointer;
                font-family: "RubikR";
                font-size: 16px;
                border: none;
                color:black;
                text-decoration: none;
                direction: rtl;
                transition: all ease-out 1s;
            }
            .button:hover{
            transform: scale(1.1);
            background: radial-gradient(circle, rgba(176, 186, 255, 0.514) 0%, rgba(252, 215, 255, 0.251) 100%);
            }  
    }
    @media only screen  and (min-width: 850px) and (max-width: 950px){
        .center{
            display: flex;
            justify-content: center;
            margin:0 auto;
            }
            .title{
                font-size:4.5rem;
                font-family: "Horev";
                text-align: center;
                margin:2% auto;
                direction:rtl;
                width:90%;
                color: black;
                text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
            }
    
        
    }