.title{
    font-family: "Horev";
    color: rgb(0, 0, 0);
    font-size:6rem;
    text-shadow: 4px 2px 4px rgba(233, 165, 156, 0.358);
  text-align: center;
  direction: rtl;
  
  width:70%;
  margin:3% auto;
}

.akordion{
    width: 50%;
     margin:0 auto;
     padding-bottom: 3%;
}
@media only screen and (max-width: 350px) {

    .title{
        font-size: 3.5rem;
       
        width:100%;
     color:black;
        justify-content: center;
        text-align: center;
        margin:3% auto 5%;
        padding-top:5%;
        font-family: "Horev";
    }
}
@media only screen and (min-width: 350px) and (max-width: 450px) {

    .title{
        font-size: 3.8rem;
       
        width:100%;
     color:black;
        justify-content: center;
        text-align: center;
        margin:0 auto 5%;
        padding-top:5%;
        font-family: "Horev";
    }
}
@media only screen and (min-width: 450px) and (max-width: 550px) {

    .title{
        font-size: 4rem;
       
        width:100%;
     color:black;
        justify-content: center;
        text-align: center;
        margin:0 auto 5%;
        padding-top:5%;
        font-family: "Horev";
    }
}
@media only screen and (min-width: 550px) and (max-width: 650px) {

    .title{
        font-size: 4rem;
       
        width:100%;
     color:black;
        justify-content: center;
        text-align: center;
        margin:0 auto 5%;
        padding-top:5%;
        font-family: "Horev";
    }
}
@media only screen and (min-width: 650px) and (max-width: 750px) {

    .title{
        font-size:4.2rem;
       
        width:100%;
     color:black;
        justify-content: center;
        text-align: center;
        margin:0 auto 5%;
        padding-top:5%;
        font-family: "Horev";
    }
}


@media only screen and (max-width: 750px) {

    .akordion{
        width: 85%;
         margin: 5% auto;
    }


}
@media only screen and (min-width: 750px) and (max-width: 1050px) {

    .akordion{
        width: 70%;
         margin: 5% auto;
    }

}
@media only screen and (min-width: 1050px) and (max-width: 1250px) {

    .akordion{
        width: 65%;
         margin: 4% auto;
    }

}

