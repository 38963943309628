.sliderContainer {

    width: 60%;
    height:auto;
    margin: 5% auto;
    
    justify-content: center;
    align-items: center;
  }
  .slick-dots {
    color: var(--slick-dots-color, inherit);
  
  }
  
  .slick-dots li button {
    color: var(--slick-dots-color, inherit);
  }
  
  .center{
    display: flex;
    width:100%;
    justify-content: center;

  }
  .image{
    width:100%;
    height:100%;

  }
  .image1{
    width:100%;
    height:100%;
    object-fit: cover;
  }
  .title{
    font-size:5rem;
    direction: rtl;
    width:90%;
  
    justify-content: center;
    text-align: center;
    margin:4% auto 0;
    font-family: "Horev";
}
.explain{
    font-size: 1.2rem;
    direction: rtl;
    width:90%;

    justify-content: center;
    text-align: center;
    margin: 3% auto;
    font-family: "HeeboR";
}
.ul{
    text-align: center;
    width:70%;
    margin:3% auto 0;
    text-align: center;
    font-family: "Horev";
    font-size: 2rem;
}
.subTitle{
    font-family: "Horev";
    font-size: 3rem;
    text-align: center;
    width:85%;
    margin:0 auto 4%;
    direction: rtl;
  }
.video{

    height:400px;
    width:250px;
    display: flex;
    margin:8% auto;
    object-fit: cover;
}

@media only screen and (max-width: 350px) {

    .sliderContainer {

        width: 80%;
        height:auto;
        margin: 5% auto;
        
        justify-content: center;
        align-items: center;
      }
      .slick-dots {
        color: var(--slick-dots-color, inherit);
      
      }
      
      .slick-dots li button {
        color: var(--slick-dots-color, inherit);
      }
      
      .center{
        display: flex;
        width:100%;
        justify-content: center;
  
      }
      .image{
        width:100%;
        height:100%;
 
      }
      .image1{
        width:100%;
        height:100%;
        object-fit: cover;
      }
      .title{
        font-size:4rem;
        direction: rtl;
        width:90%;
      
        justify-content: center;
        text-align: center;
        margin:4% auto 0;
        font-family: "Horev";
    }
    .explain{
        font-size: 1rem;
        direction: rtl;
        width:90%;
    
        justify-content: center;
        text-align: center;
        margin: 3% auto;
        font-family: "HeeboR";
    }
    .ul{
        text-align: center;
        width:70%;
        margin:3% auto 0;
        text-align: center;
        font-family: "Horev";
        font-size: 2rem;
    }
    .subTitle{
        font-family: "Horev";
        font-size: 3rem;
        text-align: center;
        width:85%;
        margin:0 auto 4%;
        direction: rtl;
      }
    .video{
    
        height:400px;
        width:250px;
        display: flex;
        margin:8% auto;
        object-fit: cover;
    }
}
@media only screen and (min-width: 350px) and (max-width: 450px) {

    .sliderContainer {

        width: 70%;
        margin:3% auto;
    height:auto;
        justify-content: center;
        align-items: center;
      }
      .ul {
     
        text-align: center;
        width: 70%;
        margin:4% auto;
        justify-content: center;
        font-family: "Horev";
        font-size: 2.5rem;
   
      }
    .subTitle{
        font-family: "Horev";
        font-size: 4rem;
        text-align: center;
        width:80%;
        margin: 0 auto 4%;
        direction: rtl;
      }
      .slick-dots {
        color: var(--slick-dots-color, inherit);
      }
      
      .slick-dots li button {
        color: var(--slick-dots-color, inherit);
      }
      
      .center{
        display: flex;
        width:100%;
        justify-content: center;
      }
      .image{
        width:100%;
        height:100%;
      
      }
      .image1{
        width:100%;
        height:100%;
        object-fit: cover;
      }
      .title{
        font-size: 4.5rem;
        direction: rtl;
        width:90%;

        justify-content: center;
        text-align: center;
        margin:2% auto 0;
        font-family: "Horev";
    }
    .explain{
        font-size: 1.2rem;
        direction: rtl;
        width:90%;
  
        justify-content: center;
        text-align: center;
        margin: 5% auto;
        font-family: "HeeboR";
    }
    .video{
    
        height:450px;
        width:300px;
        display: flex;
        margin:8% auto;
        object-fit: cover;
    }

    .subTitle{
        font-family: "Horev";
        font-size: 4rem;
        text-align: center;
        width:80%;
        margin:2% auto;
        direction: rtl;
      }
}
@media only screen and (min-width: 450px) and (max-width: 550px) {

    .sliderContainer {

        width:60%;
        margin: 4% auto;
    height: auto;
        justify-content: center;
        align-items: center;
      }
      .slick-dots {
        color: var(--slick-dots-color, inherit);
      }
      
      .slick-dots li button {
        color: var(--slick-dots-color, inherit);
      }
      
      .center{
        display: flex;
        width:100%;
        justify-content: center;
      }
      .ul{
        text-align: center;
        width:70%;
        margin:4% auto;
        font-family: "Horev";
        font-size: 2.5rem;
    }
    .subTitle{
        font-family: "Horev";
        font-size: 4rem;
        text-align: center;
        width:80%;
        margin:4% auto;
        direction: rtl;
      }
      .image{
        width:100%;
        height:100%;
      
      }
      .image1{
        width:100%;
        height:100%;
        object-fit: cover;
      }
      .title{
        font-size: 5rem;
        direction: rtl;
        width:85%;
    
        justify-content: center;
        text-align: center;
        margin:4% auto;
        font-family: "Horev";
    }
    .explain{
        font-size: 1.2rem;
        direction: rtl;
        width:90%;
     
        justify-content: center;
        text-align: center;
        margin: 5% auto;
        font-family: "HeeboR";
    }
    .video{
    
        height:450px;
        width:350px;
        display: flex;
        margin:8% auto;
        object-fit: cover;
    }
}
@media only screen and (min-width: 550px) and (max-width: 650px) {

    .sliderContainer {

        width: 40%;
        margin: auto;
        height: auto;
        justify-content: center;
        align-items: center;
      }
      .ul{
        text-align: center;
        width:70%;
        margin:4% auto;
        font-family: "Horev";
        font-size: 2.5rem;
    }
    .subTitle{
        font-family: "Horev";
        font-size: 4rem;
        text-align: center;
        width:80%;
        margin:3% auto;
        direction: rtl;
      }
      .slick-dots {
        color: var(--slick-dots-color, inherit);
      }
      
      .slick-dots li button {
        color: var(--slick-dots-color, inherit);
      }
      
      .center{
        display: flex;
        width:100%;
        justify-content: center;
      }
      .image{
        width:90%;
        height:100%;
      
      }
      .image1{
        width:100%;
        height:100%;
        object-fit: cover;
      }
      .title{
        font-size: 5.5rem;
        direction: rtl;
        width:75%;
     
        justify-content: center;
        text-align: center;
        margin:3% auto;
        font-family: "Horev";
    }
    .explain{
        font-size: 22px;
        direction: rtl;
        width:80%;
 
        justify-content: center;
        text-align: center;
        margin: 5% auto;
        font-family: "HeeboR";
    }
    .video{
    
        height:500px;
        width:400px;
        display: flex;
        margin:8% auto;
        object-fit: cover;
    }
    .ul{
        text-align: center;
        width:70%;
        margin:2% auto;
        font-family: "Horev";
        font-size: 2.5rem;
    }
    .subTitle{
        font-family: "Horev";
        font-size: 4rem;
        text-align: center;
        width:80%;
        margin:3% auto;
        direction: rtl;
      }
}
@media only screen and (min-width: 650px) and (max-width: 750px) {

    .sliderContainer {

        width: 35%;
        margin: auto;
    height:auto;
        justify-content: center;
        align-items: center;
      }
      .slick-dots {
        color: var(--slick-dots-color, inherit);
      }
      
      .slick-dots li button {
        color: var(--slick-dots-color, inherit);
      }
      .ul{
        text-align: center;
        width:70%;
        margin:3% auto;
        font-family: "Horev";
        font-size: 2.5rem;
    }
    .subTitle{
        font-family: "Horev";
        font-size: 4rem;
        text-align: center;
        width:80%;
        margin:3% auto;
        direction: rtl;
      }
      .center{
        display: flex;
        width:100%;
        justify-content: center;
      }
      .image{
        width:90%;
        height:100%;
      
      }
      .image1{
        width:100%;
        height:100%;
        object-fit: cover;
      }
      .title{
        font-size: 6rem;
        direction: rtl;
        width:75%;
        
        justify-content: center;
        text-align: center;
        margin:3% auto;
        font-family: "Horev";
    }
    .explain{
        font-size: 22px;
        direction: rtl;
        width:80%;
        
        justify-content: center;
        text-align: center;
        margin: 5% auto;
        font-family: "HeeboR";
    }
    .video{
    
        height:500px;
        width:400px;
        display: flex;
        margin:8% auto;
        object-fit: cover;
    }
}
@media only screen and (min-width: 750px) and (max-width: 850px) {

    .sliderContainer {

        width: 30%;
        margin: auto;
    height:auto;
        justify-content: center;
        align-items: center;
      }
      .slick-dots {
        color: var(--slick-dots-color, inherit);
      }
      .ul{
        text-align: center;
        width:70%;
        margin:2% auto;
        font-family: "Horev";
        font-size: 2.5rem;
    }
    .subTitle{
        font-family: "Horev";
        font-size: 4rem;
        text-align: center;
        width:80%;
        margin:3% auto;
        direction: rtl;
      }
      
      .slick-dots li button {
        color: var(--slick-dots-color, inherit);
      }
      
      .center{
        display: flex;
        width:100%;
        justify-content: center;
      }
      .image{
        width:90%;
        height:100%;
      
      }
      .image1{
        width:100%;
        height:100%;
        object-fit: cover;
      }
      .title{
        font-size: 6rem;
        direction: rtl;
        width:75%;
        
        justify-content: center;
        text-align: center;
        margin:3% auto;
        font-family: "Horev";
    }
    .explain{
        font-size: 22px;
        direction: rtl;
        width:80%;
        
        justify-content: center;
        text-align: center;
        margin: 5% auto;
        font-family: "HeeboR";
    }
    .video{
    
        height:500px;
        width:400px;
        display: flex;
        margin:8% auto;
        object-fit: cover;
    }
}
@media only screen and (min-width: 850px) and (max-width: 950px) {

    .sliderContainer {

        width: 65%;
        margin: auto;
    height: auto;
        justify-content: center;
        align-items: center;
      }
      .ul{
        text-align: center;
        width:70%;
        margin:2% auto;
        font-family: "Horev";
        font-size: 2.5rem;
    }
    .subTitle{
        font-family: "Horev";
        font-size: 4rem;
        text-align: center;
        width:80%;
        margin:3% auto;
        direction: rtl;
      }
      .slick-dots {
        color: var(--slick-dots-color, inherit);
      }
      
      .slick-dots li button {
        color: var(--slick-dots-color, inherit);
      }
      
      .center{
        display: flex;
        width:100%;
        justify-content: center;
      }
      .image{
        width:85%;
        height:100%;
      
      }
      .image1{
        width:100%;
        height:100%;
        object-fit: cover;
      }
      .title{
        font-size: 6rem;
        direction: rtl;
        width:75%;
        
        justify-content: center;
        text-align: center;
        margin:3% auto;
        font-family: "Horev";
    }
    .explain{
        font-size: 22px;
        direction: rtl;
        width:80%;
        
        justify-content: center;
        text-align: center;
        margin: 5% auto;
        font-family: "HeeboR";
    }
    .video{
    
        height:600px;
        width:450px;
        display: flex;
        margin:8% auto;
        object-fit: cover;
    }
}
@media only screen and (min-width: 950px) and (max-width: 1050px) {

    .sliderContainer {

        width: 55%;
        margin: auto;
        height: auto;
        justify-content: center;
        align-items: center;
      }
      .ul{
        text-align: center;
        width:70%;
        margin:2% auto;
        font-family: "Horev";
        font-size: 2.5rem;
    }
    .subTitle{
        font-family: "Horev";
        font-size: 4rem;
        text-align: center;
        width:80%;
        margin:2% auto;
        direction: rtl;
      }
      .slick-dots {
        color: var(--slick-dots-color, inherit);
      }
      
      .slick-dots li button {
        color: var(--slick-dots-color, inherit);
      }
      
      .center{
        display: flex;
        width:100%;
        justify-content: center;
      }
      .image{
        width:85%;
        height:100%;
      
      }
      .image1{
        width:100%;
        height:100%;
        object-fit: cover;
      }
      .title{
        font-size: 6.5rem;
        direction: rtl;
        width:75%;
        
        justify-content: center;
        text-align: center;
        margin:2% auto;
        font-family: "Horev";
    }
    .explain{
        font-size: 22px;
        direction: rtl;
        width:80%;
        
        justify-content: center;
        text-align: center;
        margin: 5% auto;
        font-family: "HeeboR";
    }
    .video{
    
        height:600px;
        width:450px;
        display: flex;
        margin:8% auto;
        object-fit: cover;
    }
}
@media only screen and (min-width: 1050px) and (max-width: 1250px) {

    .sliderContainer {

        width: 70%;
        margin: auto;
        height: auto;
        justify-content: center;
        align-items: center;
      }
      .ul{
        text-align: center;
        width:70%;
        margin:2% auto;
        font-family: "Horev";
        font-size: 2.5rem;
    }
    .subTitle{
        font-family: "Horev";
        font-size: 4rem;
        text-align: center;
        width:80%;
        margin:2% auto;
        direction: rtl;
      }
      .slick-dots {
        color: var(--slick-dots-color, inherit);
      }
      
      .slick-dots li button {
        color: var(--slick-dots-color, inherit);
      }
      
      .center{
        display: flex;
        width:100%;
        justify-content: center;
      }
      .image{
        width:80%;
        height:100%;
      
      }
      .image1{
        width:100%;
        height:100%;
        object-fit: cover;
      }
      .title{
        font-size: 7rem;
        direction: rtl;
        width:75%;
        
        justify-content: center;
        text-align: center;
        margin:1% auto;
        font-family: "Horev";
    }
    .explain{
        font-size: 22px;
        direction: rtl;
        width:75%;
        
        justify-content: center;
        text-align: center;
        margin: 2% auto;
        font-family: "HeeboR";
    }
    .video{
    
        height:600px;
        width:450px;
        display: flex;
        margin:8% auto;
        object-fit: cover;
    }
}
@media only screen and (min-width: 1250px) and (max-width: 1400px) {

    .sliderContainer {

        width: 65%;
        margin: auto;
        height: auto;
        justify-content: center;
        align-items: center;
      }
      .ul{
        text-align: center;
        width:70%;
        margin:2% auto;
        font-family: "Horev";
        font-size: 2.5rem;
    }
    .subTitle{
        font-family: "Horev";
        font-size: 4rem;
        text-align: center;
        width:80%;
        margin:2% auto;
        direction: rtl;
      }
      .slick-dots {
        color: var(--slick-dots-color, inherit);
      }
      
      .slick-dots li button {
        color: var(--slick-dots-color, inherit);
      }
      
      .center{
        display: flex;
        width:100%;
        justify-content: center;
      }
      .image{
        width:80%;
        height:100%;
      
      }
      .image1{
        width:100%;
        height:100%;
        object-fit: cover;
      }
      .title{
        font-size: 7rem;
        direction: rtl;
        width:75%;
        
        justify-content: center;
        text-align: center;
        margin:1% auto;
        font-family: "Horev";
    }
    .explain{
        font-size: 22px;
        direction: rtl;
        width:75%;
        
        justify-content: center;
        text-align: center;
        margin: 2% auto;
        font-family: "HeeboR";
    }
    .video{
    
        height:600px;
        width:450px;
        display: flex;
        margin:8% auto;
        object-fit: cover;
    }
}