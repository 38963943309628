/* .background{
    background: rgb(255,255,255);
  
    background: linear-gradient(141deg, rgba(242,114,97,0.3501050078234419) 0%, rgba(255,255,255,0.5013655120251226) 10%, rgba(242,114,97,0.3501050078234419) 20%, rgba(255,255,255,0.5013655120251226) 30%, rgba(242,114,97,0.3501050078234419) 40%, rgba(255,255,255,0.4985643915769433) 50%, rgba(255,50,22,0.20444674451811973) 60%, rgba(255,255,255,0.5041666324733018) 70%, rgba(255,50,22,0.20164562406994047) 80%, rgba(255,255,255,0.4985643915769433) 90%, rgba(255,50,22,0.20164562406994047) 100%);
} */
.title{
    font-family: "Horev";
    color: black;
    text-shadow: 3px 2px 4px rgba(0, 0, 0, 0.414);
    font-size: 6rem;
    text-align: center;
    direction: rtl;
    
    }

    .description{
        font-family: "Horev";
        color: black;
        text-shadow: 7px 2px 4px rgba(0, 0, 0, 0.414);
        font-size: 2rem;
        text-align: center;
        line-height: 1.5;
        margin:2% auto;
        direction: rtl;
        }

        .bigWrapper{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width:100%;
        }
        .step{
            width:70%;
            margin: 2% auto;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            position: relative;
        
        }
    
   
        .icons{
            height:250px;
            height:250px;
            flex:1
        }
        .text{
            display: flex;
            flex-direction: column;
            flex:1;
         margin: 3%;
         justify-content: space-evenly;
        
        }
        .stepNum{
            font-family: "Horev";
            font-size: 40px;
            text-align: center;
            color:black;
            width:100%;
            
        }
        .stepHeader{
            font-family: "RubikR";
            font-size: 45px;
            text-align: center;
            color:black;
            margin:2% auto;
            
        }
        
        
        .section{
            font-family: "RubikR";
            font-size: 22px;
            text-align: center;
            color:black;
            direction: rtl;
            
            
            width:80%;
            margin:0 auto;
        }
        @media only screen and (max-width: 350px) {
        
            .title{
                font-family: "Horev";
                color: black;
                text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
                font-size: 3rem;
                text-align: center;
                direction: rtl;
              
                margin:7% auto 3%;
                }
   
            .bigWrapper{
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width:100%;
            }
            .step{
                width:95%;
                margin: 4% auto;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
            }
            .icons{
                height:120px;
                height:120px;
                flex:1
            }
            .text{
                display: flex;
                flex-direction: column;
                flex:1.1;
             margin:3%
            
            }
        
            .stepHeader{
                font-family: "RubikR";
                font-size: 30px;
                text-align: center;
                color:black;
            width:100%;
              
            }
            
            
            .section{
                font-family: "RubikR";
                font-size: 14px;
                text-align: center;
                color:black;
                direction: rtl;
                
                width:100%;
                margin:0 auto;
            }
        }
        @media only screen and (min-width: 350px) and (max-width: 450px) {
        
     
            .title{
                font-family: "Horev";
                color: black;
                text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
                font-size: 3.5rem;
                text-align: center;
                direction: rtl;
              
                margin:5% auto 3%;
                } 
            .bigWrapper{
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width:100%;
            }
            .step{
                width:95%;
                margin: 5% auto;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
            }
            .icons{
                height:140px;
                height:140px;
                flex:1
            }
            .text{
                display: flex;
                flex-direction: column;
                flex:1.1;
             margin:3%
            
            }
        
            .stepHeader{
                font-family: "RubikR";
                font-size: 30px;
                text-align: center;
                color:black;
            width:100%;
              
            }
            
            
            .section{
                font-family: "RubikR";
                font-size: 14px;
                text-align: center;
                color:black;
                direction: rtl;
                
                width:100%;
                margin:0 auto;
            }
        }
        @media only screen and (min-width: 450px) and (max-width: 550px) {
          
            .title{
                font-family: "Horev";
                color: black;
                text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
                font-size: 3.7rem;
                text-align: center;
                direction: rtl;
              
                margin:4% auto 3%;
                }
 
            .bigWrapper{
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width:100%;
            }
            .step{
                width:95%;
                margin: 5% auto;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
            }
            .icons{
                height:140px;
                height:140px;
                flex:1
            }
            .text{
                display: flex;
                flex-direction: column;
                flex:1.1;
             margin:3%
            
            }
        
            .stepHeader{
                font-family: "RubikR";
                font-size: 35px;
                text-align: center;
                color:black;
            width:100%;
              
            }
            
            
            .section{
                font-family: "RubikR";
                font-size: 16px;
                text-align: center;
                color:black;
                direction: rtl;
                
                width:100%;
                margin:0 auto;
            }
        }
        @media only screen and (min-width: 550px) and (max-width: 650px) {
          
            .title{
                font-family: "Horev";
                color: black;
                text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
                font-size: 4rem;
                text-align: center;
                direction: rtl;
              
                margin:4% auto 3%;
                }
        

            .bigWrapper{
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width:100%;
            }
            .step{
                width:95%;
                margin: 5% auto;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
            }
            .icons{
                height:160px;
                height:160px;
                flex:1
            }
            .text{
                display: flex;
                flex-direction: column;
                flex:1.1;
             margin:3%
            
            }
        
            .stepHeader{
                font-family: "RubikR";
                font-size: 40px;
                text-align: center;
                color:black;
            width:100%;
              
            }
            
            
            .section{
                font-family: "RubikR";
                font-size: 18px;
                text-align: center;
                color:black;
                direction: rtl;
                
                width:100%;
                margin:0 auto;
            }
        }
        @media only screen and (min-width: 650px) and (max-width: 850px) {
        
            .title{
                font-family: "Horev";
                color: black;
                text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
                font-size: 4.2rem;
                text-align: center;
                direction: rtl;
              
                margin:4% auto 3%;
                } 
            .bigWrapper{
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width:100%;
            }
            .step{
                width:85%;
                margin: 5% auto;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
            }
            .icons{
                height:160px;
                height:160px;
                flex:1
            }
            .text{
                display: flex;
                flex-direction: column;
                flex:1.1;
             margin:3%
            
            }
        
            .stepHeader{
                font-family: "RubikR";
                font-size: 40px;
                text-align: center;
                color:black;
            width:100%;
              
            }
            
            
            .section{
                font-family: "RubikR";
                font-size: 18px;
                text-align: center;
                color:black;
                direction: rtl;
                
                width:100%;
                margin:0 auto;
            }
        }
        @media only screen and (min-width: 850px) and (max-width: 1050px) {
            .title{
                font-family: "Horev";
                color: black;
                text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
                font-size: 4.5rem;
                text-align: center;
                direction: rtl;
              
                margin:4% auto 3%;
                }
 
            .bigWrapper{
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width:100%;
            }
            .step{
                width:85%;
                margin: 5% auto;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
            }
            .icons{
                height:160px;
                height:160px;
                flex:1
            }
            .text{
                display: flex;
                flex-direction: column;
                flex:1.1;
             margin:3%
            
            }
        
            .stepHeader{
                font-family: "RubikR";
                font-size: 40px;
                text-align: center;
                color:black;
            width:100%;
         
            }
            
            
            .section{
                font-family: "RubikR";
                font-size: 18px;
                text-align: center;
                color:black;
                direction: rtl;
                
                width:100%;
                margin:1% auto;
            }
        }