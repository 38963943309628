.name{
    font-family: "Horev";

    font-size:9rem;
    text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.358);
  text-align: center;
  direction: rtl;


}
.center{
  width:40%;
  margin:0 auto
}
.image{
  width: 100%;
  object-fit: contain;

}

.row{
    display: flex;
  flex-direction: row;
  padding-top:2%;
  padding-bottom: 2%;
    justify-content: space-evenly;
}
.quate{
    font-size: 2.2rem;
    text-align: center;
    font-family: "Horev";
    width:30%;
   display: flex;
  padding-top: 2% ;
margin :0 auto;
   direction: rtl;
}
@media only screen and (max-width: 350px){



  .name{
    font-family: "Horev";

    font-size:5rem;
    text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.358);
  text-align: center;
  direction: rtl;


}
.center{
  width:90%;
  margin:0 auto
}
.image{
  width: 100%;
  object-fit: contain;

}

.row{
    display: flex;
  flex-direction: row;
margin:2% auto;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.quate{
    font-size: 1.8rem;
    text-align: center;
    font-family: "Horev";
    width:80%;
   display: flex;
  padding-top: 4% ;
margin :0 auto;
   direction: rtl;
}

}
@media only screen and (min-width: 350px) and (max-width: 450px){



  .name{
    font-family: "Horev";

    font-size:5.5rem;
    text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.358);
  text-align: center;
  direction: rtl;


}
.center{
  width:85%;
  margin:0 auto
}
.image{
  width: 100%;
  object-fit: contain;

}

.row{
    display: flex;
  flex-direction: row;
  padding-top:2%;
  padding-bottom: 2%;
  flex-wrap: wrap;
    justify-content: space-evenly;
}
.quate{
    font-size: 2rem;
    text-align: center;
    font-family: "Horev";
    width:80%;
   display: flex;
  padding-top: 2% ;
margin :0 auto;
   direction: rtl;
}

}
@media only screen and (min-width: 450px) and (max-width: 550px){



  .name{
    font-family: "Horev";

    font-size:6rem;
    text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.358);
  text-align: center;
  direction: rtl;


}
.center{
  width:85%;
  margin:0 auto
}
.image{
  width: 100%;
  object-fit: contain;

}

.row{
    display: flex;
  flex-direction: row;
  padding-top:2%;
  padding-bottom: 2%;
  flex-wrap: wrap;
    justify-content: space-evenly;
}
.quate{
    font-size: 2.3rem;
    text-align: center;
    font-family: "Horev";
    width:80%;
   display: flex;
  padding-top: 2% ;
  flex-wrap: wrap;
margin :0 auto;
   direction: rtl;
}

}
@media only screen and (min-width: 550px) and (max-width: 650px){



  .name{
    font-family: "Horev";

    font-size:6.5rem;
    text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.358);
  text-align: center;
  direction: rtl;


}
.center{
  width:80%;
  margin:0 auto
}
.image{
  width: 100%;
  object-fit: contain;

}

.row{
    display: flex;
  flex-direction: row;
  padding-top:2%;
  padding-bottom: 2%;
  flex-wrap: wrap;
    justify-content: space-evenly;
}
.quate{
    font-size: 2.5rem;
    text-align: center;
    font-family: "Horev";
    width:75%;
   display: flex;
  padding-top: 2% ;
  flex-wrap: wrap;
margin :0 auto;
   direction: rtl;
}

}
@media only screen and (min-width: 650px) and (max-width: 750px){



  .name{
    font-family: "Horev";

    font-size:7rem;
    text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.358);
  text-align: center;
  direction: rtl;
}
.center{
  width:80%;
  margin:0 auto
}
.image{
  width: 100%;
  object-fit: contain;

}

.row{
    display: flex;
  flex-direction: row;
  padding-top:2%;
  padding-bottom: 2%;
  flex-wrap: wrap;
    justify-content: space-evenly;
}
.quate{
    font-size: 2.8rem;
    text-align: center;
    font-family: "Horev";
    width:75%;
   display: flex;
  padding-top: 2% ;
  flex-wrap: wrap;
margin :0 auto;
   direction: rtl;
}

}
@media only screen and (min-width: 750px) and (max-width: 850px){



  .name{
    font-family: "Horev";

    font-size:7rem;
    text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.358);
  text-align: center;
  direction: rtl;
}
.center{
  width:70%;
  margin:0 auto
}
.image{
  width: 100%;
  object-fit: contain;

}

.row{
    display: flex;
  flex-direction: row;
  padding-top:2%;
  padding-bottom: 2%;
  flex-wrap: wrap;
    justify-content: space-evenly;
}
.quate{
    font-size: 2.5rem;
    text-align: center;
    font-family: "Horev";
    width:75%;
   display: flex;
  padding-top: 2% ;
  flex-wrap: wrap;
margin :0 auto;
   direction: rtl;
}

}
@media only screen and (min-width: 850px) and (max-width: 1050px){

  .name{
    font-family: "Horev";

    font-size:7.5rem;
    text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.358);
  text-align: center;
  direction: rtl;
}
.center{
  width:70%;
  margin:0 auto
}
.image{
  width: 100%;
  object-fit: contain;

}

.row{
    display: flex;
  flex-direction: row;
  padding-top:2%;
  padding-bottom: 2%;
  flex-wrap: wrap;
    justify-content: space-evenly;
}
.quate{
    font-size: 2.5rem;
    text-align: center;
    font-family: "Horev";
    width:55%;
   display: flex;

  flex-wrap: wrap;
margin :2% auto;
   direction: rtl;
}

}
@media only screen and (min-width: 1050px) and (max-width: 1250px){

  .name{
    font-family: "Horev";

    font-size:8rem;
    text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.358);
  text-align: center;
  direction: rtl;
}
.center{
  width:60%;
  margin:0 auto
}
.image{
  width: 100%;
  object-fit: contain;

}

.quate{
    font-size: 2.5rem;
    text-align: center;
    font-family: "Horev";
    width:55%;
   display: flex;

  flex-wrap: wrap;
margin :2% auto;
   direction: rtl;
}

}