.row{
margin:2% auto;
}
.header{
    text-align: center;
    font-family: "Horev";
    font-size: 5rem;
    margin:0 auto;
}
.description{
    font-family: "HeeboR";
    font-size: 1.4rem;
    text-align: center;
    margin:0 auto 2%;
}

@media only screen and (max-width: 450px){

    .row{
        margin:4% auto;
        }
        .header{
            text-align: center;
            font-family: "Horev";
            font-size: 3.5rem;
         padding-top:3%;
            margin:2% auto;
        }
        .description{
            font-family: "HeeboR";
            font-size: 1rem;
            text-align: center;
            margin:0 auto 2%;
        }

}

@media only screen  and (min-width: 450px) and (max-width: 550px){

            .row{
                margin:4% auto;
                }
                .header{
                    text-align: center;
                    font-family: "Horev";
                    font-size: 4rem;
                    padding-top:3%;
                    margin:2% auto;
                }
                .description{
                    font-family: "HeeboR";
                    font-size: 1rem;
                    text-align: center;
                    margin:0 auto 2%;
                }
        
        }    
        @media only screen  and (min-width: 550px) and (max-width: 750px){

            .row{
                margin:3% auto;
                }
                .header{
                    text-align: center;
                    font-family: "Horev";
                    font-size: 4.2rem;
                    padding-top:3%;
                    margin:2% auto;
                }
                .description{
                    font-family: "HeeboR";
                    font-size: 1.1rem;
                    text-align: center;
                    margin:0 auto 2%;
                }
        
        }    
        @media only screen  and (min-width: 750px) and (max-width: 950px){

            .row{
                margin:3% auto;
                }
                .header{
                    text-align: center;
                    font-family: "Horev";
                    font-size: 4.5rem;
                    padding-top:3%;
                    margin:2% auto;
                }
                .description{
                    font-family: "HeeboR";
                    font-size: 1.2rem;
                    text-align: center;
                    margin:0 auto 2%;
                }
        
        }    
        @media only screen  and (min-width: 950px) and (max-width: 1150px){

            .row{
                margin:3% auto;
                }
                .header{
                    text-align: center;
                    font-family: "Horev";
                    font-size: 4.8rem;
                    padding-top:3%;
                    margin:2% auto;
                }
                .description{
                    font-family: "HeeboR";
                    font-size: 1.2rem;
                    text-align: center;
                    margin:0 auto 2%;
                }
        
        }    
        @media only screen  and (min-width: 1150px) and (max-width: 1350px){

            .row{
                margin:3% auto;
                }
                .header{
                    text-align: center;
                    font-family: "Horev";
                    font-size: 5rem;
                    padding-top:3%;
                    margin:2% auto;
                }
                .description{
                    font-family: "HeeboR";
                    font-size: 1.2rem;
                    text-align: center;
                    margin:0 auto 2%;
                }
        
        }    